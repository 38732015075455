//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: "EmptyMessage",
    props: [
        'icon',
        'title',
        'description'
    ]
}
