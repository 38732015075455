//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import PostLoading from '@/components/Posts/PostLoading'
import EmptyMessage from '@/components/EmptyMessage'
import PostCreator from '@/components/Posts/PostCreator'
import UptoDate from '@/components/Posts/UptoDate'
export default {
  name: 'PostContainer',
  props: [
    'from',
    'id',
    'isOwner'
  ],
  data() {
    return {
      isAllowedToPost: true,
      isEmpty: false,
      isLoading: false,
      posts: [],
      page: 1,
      timestamp: 0,
      avatar: '',
      isUptoDate: false,
      bottomReached : false
    }
  },
  components: {
    PostLoading,
    EmptyMessage,
    PostCreator,
    UptoDate,
    Post: () => import('@/components/Posts/Post')
  },
  created() {
    if (this.from == undefined) {
      return;
    }
    this.getPosts();
  }, 
  methods: {
    addCreatedPost(post) {
      post['first_name'] = this.$store.state.user.first_name;
      post['last_name'] = this.$store.state.user.last_name
      if (this.posts.length > 1) {
        this.posts.pop();
        this.posts.unshift(post);
      } else {
        this.isEmpty = false;
        this.posts.push(post);
      }
    },
    /**
     * Refreshes the post container and retrieves
     * latest post.
     */
    refresh(done) {
      console.log('The pull has been triggerred.')
      this.isUptoDate = false;
      this.getPosts(true, done, false);
    },
    
    /**
     * Retrieves posts from the server.
     * 
     * @param { Boolean } latest - Indicate if will be retrieving latest po or by page.
     * @param { Function } done - Pass a done function when refresh state is triggered.
     */
    getPosts(latest = true, done = undefined, firstLoad = true) {
      let hasTimestamp = (this.timestamp == 0) ? false : true;
      let target = this.from;
      let endpoint = '';
      if (target === undefined) {
        this.isEmpty = true;
        return;
      }

      switch (target) {
        case 'home':
          if (latest) {
            endpoint = (hasTimestamp)
              ? '/posts?limit=25&timestamp=' + this.timestamp
              : '/posts?limit=25';
          } else {
            this.page += 1;
            endpoint = '/posts?page='+ this.page +'&limit=25';
          }
        break;
        default:
          if (latest) {
            endpoint = (hasTimestamp)
              ? target + '/' + this.id + '/posts?page=1&limit=25&timestamp='+ this.timestamp
              : target + '/' + this.id + '/posts?page=1&limit=25';
          }  else {
            this.page += 1;
            endpoint = target + '/' + this.id + '/posts?page='+ this.page +'&limit=25';
          }
        break;
      }

      setTimeout(() => {
        this.$http({
          url: endpoint,
          method: 'GET'
        }).then((response) => {
          switch(response.data['status']) {
            case 'posts_empty':
              this.isLoading = false;
              if (latest) {
                this.isUptoDate = true;
                if (firstLoad) {
                  this.isEmpty = true;
                }
              } else {
                this.page -= 1;
                if (firstLoad) {
                  this.isEmpty = true;
                } else {
                  this.bottomReached = true;
                }
              }
              if (typeof done === 'function') {
                done();
              }
            break;
            case 'posts_retrieved':
              this.isEmpty = false;
              this.isLoading = false;
              if (latest) {
                const newPosts = response.data['body']['posts'].reverse();
                newPosts.forEach((post) => {
                  this.posts.unshift(post);
                });
              } else {
                const newPosts = response.data['body']['posts'];
                newPosts.forEach((post) => {
                  this.posts.push(post);
                });
              }
              this.timestamp = parseInt(response.data['body']['timestamp']);

              if (firstLoad) {
                this.scroll();
              }

              if (typeof done === 'function') {
                done();
              }
            break;
          }
        }).catch((err) => {
          console.error(err);
        })
      }, 500);
    },

    /**
     * Attaches a bottom scroll listener when this component
     * mounts.
     */
    scroll () {
      window.onscroll = () => {
        let bottomOfWindow = Math.max(
          window.pageYOffset, 
          document.documentElement.scrollTop, 
          document.body.scrollTop) 
          + window.innerHeight === document.documentElement.offsetHeight

        if (bottomOfWindow) {
          this.getPosts(false, undefined, false);
        }
      }
    }
  }
}
