//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: 'MediaPreviewer',
    props: {
        medias: {
            type: Array,
            required: true,
        }
    },
    data() {
        return {
            mediaPreviewList: [],
            isPreviewReady: false,
            test: 'hello'
        }
    },
    methods: {
        removeLoadingSpinner()
        {
            setTimeout(() => { this.isPreviewReady = true }, 1000);
        },
        runParser(files) {
            this.isPreviewReady = false;
            this.mediaPreviewList = [];
            var i = 0;

            files.forEach((media) => {
                if (media.type.match('image/*')) {
                    let reader = new FileReader();
                    reader.onload = (e) => {
                        this.mediaPreviewList.push({
                            id: i,
                            src: e.target.result,
                            type: media.type
                        });
                        i++;
                    }

                    reader.readAsDataURL(media);
                } else {
                    this.mediaPreviewList.push({
                        id: i,
                        src: '@/assets/images/video_thumb.jpg',
                        type: media.type
                    });
                    i++;
                }
            });
            this.removeLoadingSpinner();
        }
    },
    watch: {
        medias: {
            handler(files) {
                this.runParser(files);
            }
        }
    },
    created() {
        this.runParser(this.medias);
    }
}
