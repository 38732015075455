//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import MediaPreviewer from '@/components/Posts/MediaPreviewer'
export default {
    name: 'PostCreator',
    props: ['avatar', 'id', 'from'],
    data() {
        return {
            hasFiles: false,
            isSending: false,
            postBody: null,
            progress: 0,
            files: []
        }
    },
    components: {
        MediaPreviewer
    },
    methods: {
        createPost()
        {
            this.isSending = true;
            this.$refs.input.resetValidation();

            var formData = new FormData();
            formData.append('body', this.postBody);

            if (this.from == "entities") {
                formData.append('entity_id', this.id);
            }

            if (this.files.length > 0) {
                this.files.forEach((file) => {
                    formData.append('medias[]', file);
                });

                this.hasFiles = true;
            }

            if (this.postBody === null && !this.hasFiles) {
                this.postBody = '';
                this.progress = 0;
                this.hasFiles = false;
                this.files = [];
                this.isSending = false;
                this.$q.notify({
                    message:'Please enter something on the text box.',
                    color:'negative',
                    icon: 'error'
                });

                return;
            }

            if (this.postBody == '' && !this.hasFiles) {
                this.postBody = '';
                this.progress = 0;
                this.hasFiles = false;
                this.files = [];
                this.isSending = false;
                this.$q.notify({
                    message:'Please enter something on the text box.',
                    color:'negative',
                    icon: 'error'
                });

                return;
            }

            this.$http({
                url: 'posts',
                method: 'POST',
                data: formData,
                headers: { 'Content-Type': 'multipart/form-data' },
                onUploadProgress: function( progressEvent ) {
                    this.progress = parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100 ))
                }.bind(this)
            }).then((response) => {
                this.isSending = false;
                switch (response.data['status']) {
                    case 'post_created':
                        this.postBody = '';
                        this.progress = 0;
                        this.hasFiles = false;
                        this.files = [];

                        this.$q.notify({
                            message: 'Your post has been successfully shared.',
                            color: 'positive',
                            icon: 'check_circle'
                        });
                        this.$emit('created', response.data['body']['post']);
                    break;
                    default: 
                        this.$q.notify({
                            message:'We cannot create your post. Please try again.',
                            color:'negative',
                            icon: 'error'
                        });
                    break;
                }
            }).catch((err) => {
                if (err.response.status == 500) {
                    this.progress = 0;
                    this.isSending = false;

                    this.$q.dialog({
                        title:'Well, that is embarassing..',
                        message: 'There has been an error while trying to share your post. This can be because of unsupported attached video or images. Rest assured that our team is working on this to support all types of possible attachments.'
                    });
                }
            })
        },
        checkFiles()
        {
            if (this.files.length > 10) {
                this.files = this.files.slice(0, 10);

                this.$q.notify({
                    message:'You can only attach 10 medias on a post.',
                    color:'warning',
                    icon: 'error'
                });
            }
        }
    }

}
